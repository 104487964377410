@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: JosefinSans;
  src: url("assets/fonts/JosefinSans.ttf");
}

  p {
    @apply text-sm sm:text-base dark:text-white;
  }

  small {
    @apply text-xs sm:text-sm dark:text-white;
  }

  h2 {
    @apply text-2xl sm:text-3xl font-semibold dark:text-white;
  }

  h4 {
    @apply text-lg sm:text-xl font-semibold dark:text-white;
  }

  h5 {
    @apply dark:text-white;
  }

  h3 {
    @apply text-xl sm:text-2xl font-semibold dark:text-white;
  }


  h1 {
    @apply text-3xl md:text-5xl font-semibold dark:text-white;
  }

  th {
    @apply dark:text-white;
  }

  td {
    @apply dark:text-white;
  }

  iframe
{
    display: none;
}

  .fade-around {
    /* scroll bar width, for use in mask calculations */
    --scrollbar-width: 0px;
    /* mask fade distance, for use in mask calculations */
    --mask-height: 16px;
    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        white var(--mask-height),
        white calc(100% - var(--mask-height)),
        transparent
    );

    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(white, white);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    mask-position: 0 0, 100% 0;
    mask-repeat: no-repeat, no-repeat;
}

.tiny {
  font-size: xx-small;
  margin: 0;
  font-weight: 600;
}

.fade-sideways {
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 0px;
  /* mask fade distance, for use in mask calculations */
  --mask-width: 16px;
  --mask-image-content: linear-gradient(
      to right,
      transparent,
      white var(--mask-width),
      white calc(100% - var(--mask-width)),
      transparent
  );

  --mask-size-content: 100% calc(100% - var(--scrollbar-width));
  --mask-image-scrollbar: linear-gradient(white, white);
  --mask-size-scrollbar: 100% var(--scrollbar-width);
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 0 100%;
  mask-repeat: no-repeat, no-repeat;
}

.circle-style {
  display: block;
  width: 3rem;
  height: 3rem;
  border: 0.5rem solid #e9e9e9;
  border-top: 0.5rem solid #6482f7;
  border-radius: 50%;
  box-sizing: border-box;
  @apply dark:border-t-blue-800 dark:border-gray-600;
}

.light-circle-style {
  display: block;
  width: 3rem;
  height: 3rem;
  border: 0.5rem solid #e9e9e9;
  border-top: 0.5rem solid #6482f7;
  border-radius: 50%;
  box-sizing: border-box;
}

.apexcharts-svg {
  overflow: visible;
}

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

  